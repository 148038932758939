import React from "react";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import CloseIcon from '../assets/tache-icon.png';
import NexterButton from "./shared/NexterButton";

const Modal = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 20px;
  margin: 0 auto;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1010;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalContent = styled.div<{ themeColor: string }>`
  box-sizing: border-box;
  position: relative;
  width: 90%;
  max-width: 1238px;
  height: 80%;
  max-height: 600px;
  background-color: #fff;
  border-radius: 24px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 12px;
    left: 12px;
    width: 100%;
    height: 100%;
    border: 3px solid ${({ themeColor }) => themeColor};
    border-radius: 10px;
  }
`;

const ModalBody = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 40px;
`;

export default function ManualModal({ show = false, onClose, themeColor = "#e5e5e5" }) {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setOpen(show);
  }, [show]);

  if (!open) return null;

  return createPortal(
    <>
      <Modal>
        <ModalContent themeColor={themeColor} >
          <ModalBody>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "20px",
              }}
            >
              <span
                style={{ cursor: "pointer" }}
                onClick={() => onClose()}
              >
                <img
                  src={CloseIcon} alt="Close Icon"
                  style={{ width: 'auto', height: '30px' }}
                  crossOrigin='anonymous'
                />
              </span>
            </div>
            <div>
              <h1
                style={{
                  fontSize: "32px",
                  fontWeight: "bold",
                  color: "#000",
                  marginBottom: "20px",
                  textAlign: "center",
                }}
              >¿Para qué?</h1>
            </div>
            <div>
              <p style={{ marginBottom: "40px", textAlign: "center" }}>
                Por medio de esta prueba mediremos tu ritmo cardiaco, fecuencia respiratoria, nivel de estrés y presión arterial.
              </p>
              <h2 className="section-title">Instrucciones</h2>
              <ol>
                <li>Concede los permisos para acceder a la cámara.</li>
                <li>
                  <span className="d-inline-flex align-items-center">
                    <span>Haz clic en el botón de iniciar.</span>
                    <span></span>
                  </span>
                </li>
                <li>
                  Mantén la mirada en la cámara durante segundos, procurando no parpadear demasiado.
                </li>
                <li>
                  Espera hasta que el contador se detenga y marque Duración: 60 segundos.
                </li>
              </ol>
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '40px',
              }}>
                <NexterButton onClick={() => onClose()}>
                  Entendido
                </NexterButton>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>,
    document.body
  );
}