import React from "react";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import CloseIcon from '../assets/tache-icon.png';
import moment from 'moment-timezone';

const Modal = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 20px;
  margin: 0 auto;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1010;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalContent = styled.div<{ themeColor: string }>`
  box-sizing: border-box;
  position: relative;
  width: 90%;
  max-width: 1238px;
  height: 80%;
  max-height: 600px;
  background-color: #fff;
  border-radius: 24px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 12px;
    left: 12px;
    width: 100%;
    height: 100%;
    border: 3px solid ${({ themeColor }) => themeColor};
    border-radius: 10px;
  }
`;

const ModalBody = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 40px;
`;

const TableResponsive = styled.div`
  width: 100%;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  & th {
    background-color: #000;
    color: #fff;
    border-radius: 10px;
  }

  & th, & td {
    padding: 10px;
    text-align: center;
  }
`;

export default function HistoryModal({ show = false, onClose, nexterEnv, userToken, returnTo, themeColor = "#e5e5e5" }) {
  const [open, setOpen] = useState(false);
  const [history, setHistory] = useState([]);
  const endpoints = {
    'dev': 'http://localhost:3333/api/v1',
    'staging': 'https://webservice-test.nexter.mx/api/v1',
    'prod': 'https://webservice.nexter.mx/api/v1'
  }

  const formatDate = (date: string): string => {
    const currentDate = moment(date);
    const dateWithTimeZone = currentDate.tz('America/Mexico_City');
    return dateWithTimeZone.format('DD/MM/YYYY HH:mm A');
  }

  useEffect(() => {
    setOpen(show);
    if (show && userToken) {
      console.log('monitor:Fetching history', userToken);
      fetch(`${endpoints[nexterEnv]}/external/services/health/binah`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userToken}`,
        },
      })
      .then(response => response.json())
      .then(data => {
        if (data.errors) {
          location.href = returnTo;
          return;
        }
        const results = data.results;
        setHistory(results);
      })
      .catch(error => console.error('monitor:Error:', error))
    };
  }, [show, nexterEnv, userToken]);

  if (!open) return null;

  return createPortal(
    <>
      <Modal>
        <ModalContent themeColor={themeColor}>
          <ModalBody>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "20px",
              }}
            >
              <span
                style={{ cursor: "pointer" }}
                onClick={() => onClose()}
              >
                <img
                  src={CloseIcon} alt="Close Icon"
                  style={{ width: 'auto', height: '30px' }}
                  crossOrigin='anonymous'
                />
              </span>
            </div>
            <div>
              <h1
                style={{
                  fontSize: "32px",
                  fontWeight: "bold",
                  color: "#000",
                  marginBottom: "20px",
                  textAlign: "center",
                }}
              >Tu historial</h1>
            </div>
            <TableResponsive>
              <Table>
                <thead>
                  <tr>
                    <th>Fecha y hora</th>
                    <th>Ritmo cardiáco</th>
                    <th>Frecuencia respiratoria</th>
                    <th>Nivel de estrés</th>
                    <th>Presión arterial</th>
                  </tr>
                </thead>
                <tbody>
                  {history.length > 0 ? (
                    <>
                      {history.map((item) => (
                        <tr key={item.id}>
                          <td>{formatDate(item.created_at)}</td>
                          <td>{item.heart_rate}</td>
                          <td>{item.breathing_rate}</td>
                          <td>{item.stress}</td>
                          <td>
                            <span  dangerouslySetInnerHTML={{__html: item.blood_pressure}} />
                          </td>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <tr>
                      <td colSpan={5} style={{ textAlign: "center" }}>
                        Aún no hay registros.
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </TableResponsive>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>,
    document.body
  );
}