import { createLocalStorageStateHook } from 'use-local-storage-state';

export const LICENSE_LEY = '673B1E-E5EE88-4B49A1-573886-6200E0-64374F'
export const DEFAULT_MEASUREMENT_DURATION = 60;
export const MIN_MEASUREMENT_DURATION = 60;
export const MAX_MEASUREMENT_DURATION = 180;

export const useLicenseKey = function () { return [LICENSE_LEY] };
export const useProductId = createLocalStorageStateHook('productId', null);
export const useMeasurementDuration = createLocalStorageStateHook(
  'measurementDuration',
  DEFAULT_MEASUREMENT_DURATION,
);
