import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import {
  isMobile,
  isTablet,
  isIos,
  HealthMonitorCodes,
  SessionState,
} from '@binah/web-sdk';
import { useMediaPredicate } from 'react-media-hook';
import {
  useError,
  useLicenseKey,
  useMeasurementDuration,
  useMonitor,
  usePageVisibility,
  usePrevious,
  useWarning,
} from '../hooks';
import Stats from './Stats';
import StartButton from './StartButton';
import { mirror } from '../style/mirror';
import { Flex } from './shared/Flex';
import media from '../style/media';
import InfoBar from './InfoBar';
import { ErrorAlert, InfoAlert } from './alert';
import Loader from './Loader';
import { VideoReadyState } from '../types';
import Mask from '../assets/mask.svg';
import CloseIcon from '../assets/tache-icon.png';
import ClipboardIcon from '../assets/clipboard.svg';
import HistoryModal from './HistoryModal';
import { useFirstMountState } from 'react-use';
import ManualModal from './ManualModal';
import Overlay from './overlay';

const Toolbar = styled(Flex)`
  height: 60px;
  width: 100%;
  max-width: 1480px;
  margin: 0 auto;
  position: relative;
  justify-content: space-between;
  align-items: center;
  z-index: 1010;
`;

const MonitorWrapper = styled(Flex)<{ isMobile: boolean }>`
  display: flex;
  position: relative;
  width: 100%;
  max-width: 1480px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  height: ${({ isMobile }) => isMobile ? '80%' : '100%'};
`;

const VideoAndStatsWrapper = styled(Flex)<{ isMobile: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  width: 100%;
  height: 80%;
`;

const VideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #000;
`;

const Img = styled.img<{ isDesktop: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  object-fit: ${({ isDesktop }) => (isDesktop ? 'contain' : 'cover')};
`;

const Video = styled.video<{ isMobile: boolean }>`
  width: 100%;
  height: 100%;
  object-fit: ${() => (isMobile() ? 'cover' : 'contain')};
  ${mirror}
`;

const ButtonWrapper = styled(Flex)<{ isMobile: boolean }>`
  position: absolute;
  flex: 2;
  z-index: 3;
  width: 100%;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  bottom: -22px;
`;

const ButtomTimerWrapper = styled(Flex)`
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  height: 30px;
  display: flex;
`;

const InfoBarWrapper = styled.div`
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  display: flex;
  align-items: flex-end;
  ${media.mobile`
    flex: 0.45;
  `}
`;

const BinahMonitor = ({
  showMonitor,
  cameraId,
  onLicenseStatus,
  onSettingsClick,
  isSettingsOpen,
  userToken,
  nexterEnv,
  colorBrand,
  imageBrand,
  signedToken,
  policyId
}) => {
  if (!showMonitor) {
    return null;
  }
  const video = useRef<HTMLVideoElement>(null);
  const [isMeasurementEnabled, setIsMeasurementEnabled] = useState<boolean>(
    false,
  );
  const [startMeasuring, setStartMeasuring] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingTimeoutPromise, setLoadingTimeoutPromise] = useState<number>();
  const isPageVisible = usePageVisibility();
  const isMediaTablet = useMediaPredicate('(min-width: 1000px)');
  const [processingTime] = useMeasurementDuration();
  const [licenseKey] = useLicenseKey();
  const {
    sessionState,
    vitalSigns,
    offlineMeasurements,
    error,
    warning,
    info,
  } = useMonitor(
    video,
    cameraId,
    processingTime,
    licenseKey,
    null,
    startMeasuring,
    userToken,
    nexterEnv
  );
  const prevSessionState = usePrevious(sessionState);
  const errorMessage = useError(error);
  const warningMessage = useWarning(warning);
  const [openHistory, setOpenHistory] = useState(false);
  const [openManual, setOpenManual] = useState(false);
  const [isVerifying, setIsVerifying] = useState(true);
  const [authStatus, setAuthStatus] = useState<string>("Verifying");
  const isFirstMount = useFirstMountState();
  const returnLinks = {
    dev: 'http://localhost:4200',
    staging: 'https://webapp-test.nexter.mx',
    prod: 'https://webapp.nexter.mx'
  }
  const endpoints = {
    'dev': 'http://localhost:3333/api/v1',
    'staging': 'https://webservice-test.nexter.mx/api/v1',
    'prod': 'https://webservice.nexter.mx/api/v1'
  }

  const isMeasuring = useCallback(
    () => sessionState === SessionState.MEASURING,
    [sessionState],
  );

  const isVideoReady = useCallback(
    () => video.current?.readyState === VideoReadyState.HAVE_ENOUGH_DATA,
    [],
  );

  const handleButtonClick = useCallback(() => {
    setIsLoading(true);
    if (sessionState === SessionState.ACTIVE) {
      setStartMeasuring(true);
      setLoadingTimeoutPromise(
        window.setTimeout(() => setIsLoading(true), processingTime * 1000),
      );
    } else if (isMeasuring()) {
      clearTimeout(loadingTimeoutPromise);
      setStartMeasuring(false);
    }
  }, [sessionState, setIsLoading, processingTime]);

  const openHistoryModal = () => {
    setOpenHistory(true);
  };
  const requestVerifyAuth = async (userToken: string, signedToken: string) => {
    setIsVerifying(true);
    const response = await fetch(
      `${endpoints[nexterEnv]}/external/services/health/binah/auth/verify`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userToken}`,
        },
        body: JSON.stringify({
          token: signedToken,
        })
      }
    );
    if (!response.ok) {
      setIsVerifying(false);
      alert('Error al verificar la autenticación');
    } else {
      const data = await response.json();
      if (!data["results"]["access"]) {
        setAuthStatus(data["results"]["message"]);
      } else {
        setIsVerifying(false);
      }
    }
  };

  useEffect(() => {
    if (isMeasuring()) {
      setIsLoading(false);
      if (errorMessage) {
        setIsMeasurementEnabled(false);
      } else {
        setIsMeasurementEnabled(true);
      }
      !isPageVisible && setStartMeasuring(false);
    } else if (
      (sessionState === SessionState.ACTIVE ||
        sessionState === SessionState.TERMINATED) &&
      errorMessage
    ) {
      setIsMeasurementEnabled(false);
    }
    if (
      sessionState === SessionState.ACTIVE &&
      prevSessionState !== sessionState
    ) {
      setStartMeasuring(false);
      setIsLoading(false);
    }
  }, [errorMessage, sessionState, isPageVisible]);

  useEffect(() => {
    onLicenseStatus(!(error?.code in HealthMonitorCodes));
  }, [error]);

  useEffect(() => {
    if (isFirstMount) {
      setOpenManual(true);
    }
    if (userToken && signedToken) {
      requestVerifyAuth(userToken, signedToken);
    }
  }, [isFirstMount, userToken, signedToken]);

  const mobile = useMemo(() => isMobile(), []);
  const desktop = useMemo(() => !isTablet() && !isMobile(), []);

  return (
    <>
      <div
        style={{
          width: '100%',
          backgroundColor: colorBrand,
          padding: '0 1.5rem',
          boxSizing: 'border-box',
        }}
      >
        <Toolbar>
          <a href={returnLinks[nexterEnv]}>
            <img
              src={imageBrand} alt="Nexter Logo"
              crossOrigin="anonymous"
              style={{ width: 'auto', height: '36px' }}
            />
          </a>
          <div style={{
            display: 'flex',
            gap: '1rem',
            alignItems: 'center',
          }}>
            <a
              href="#"
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '1rem',
                color: '#000',
                textDecoration: 'none',
              }}
              onClick={(event) => {
                event.preventDefault();
                openHistoryModal();
              }}
            >
              Historial
              <img
                src={ClipboardIcon} alt="Histotial"
                style={{ width: 'auto', height: '30px' }}
                crossOrigin='anonymous'
              />
            </a>
            <a
              href={returnLinks[nexterEnv]}
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '1rem',
                color: '#000',
                textDecoration: 'none',
              }}
            >
              Cerrar
              <img
                src={CloseIcon} alt="Close Icon"
                style={{ width: 'auto', height: '30px' }}
                crossOrigin='anonymous'
              />
            </a>
          </div>
        </Toolbar>
      </div>
      <MonitorWrapper isMobile={mobile}>
        <InfoBarWrapper>
          <InfoBar
            showTimer={isMeasurementEnabled}
            isMeasuring={isMeasuring()}
            durationSeconds={processingTime}
            offlineMeasurements={offlineMeasurements}
          />
        </InfoBarWrapper>
        <VideoAndStatsWrapper isMobile={mobile}>
          <VideoWrapper>
            <Img src={Mask} isDesktop={desktop} />
            <Video
              ref={video}
              id="video"
              muted={true}
              playsInline={true}
              isMobile={isMobile()}
            />
          </VideoWrapper>
          {(isMeasuring()
            ? !errorMessage && !warningMessage
            : !errorMessage) &&
            isMeasurementEnabled && <></>}
          <ErrorAlert message={errorMessage} />
          {isMeasuring() && <></>/* <WarningAlert message={warningMessage} />*/}
          {isMeasuring() && <InfoAlert message={info.message} />}
          {!isVideoReady() && licenseKey && <Loader />}
          <ButtonWrapper isMobile={isMobile()}>
            <StartButton
              isLoading={isLoading}
              isMeasuring={isMeasuring()}
              onClick={handleButtonClick}
            />
          </ButtonWrapper>
        </VideoAndStatsWrapper>
        {/*<ButtomTimerWrapper>
          {isMeasurementEnabled && (
            <Timer started={isMeasuring()} durationSeconds={processingTime} />
          )}
          </ButtomTimerWrapper>*/}
        <Stats vitalSigns={vitalSigns} />
      </MonitorWrapper>
      <HistoryModal
        show={openHistory}
        nexterEnv={nexterEnv}
        userToken={userToken}
        returnTo={returnLinks[nexterEnv]}
        onClose={() => setOpenHistory(false)}
        themeColor={colorBrand}
      />
      <ManualModal
        show={openManual}
        onClose={() => setOpenManual(false)}
        themeColor={colorBrand}
      />
      <Overlay loading={isVerifying}>
        {authStatus === "Verifying" && <p>Verficando...</p>}
        {authStatus === "Token required" || authStatus === "Token expired" && (
          <div style={{ display: "flex", flexDirection: "column", gap: "0.25rem" }}>
            <p>La sesión expiró, por favor refresca para continuar:</p>
            <a href={`${returnLinks[nexterEnv]}/cliente/checar-salud/${policyId}`}>Refrescar</a>
          </div>
        )}
      </Overlay>
    </>
  );
};

export default BinahMonitor;
