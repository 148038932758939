import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import BinahMonitor from './BinahMonitor';
import { Flex } from './shared/Flex';
import { useCameras, useDisableZoom } from '../hooks';
import UAParser from 'ua-parser-js';
import NexterLogo from '../assets/logonexternegro.svg';

const Container = styled(Flex)`
  height: 100%;
  width: 100%;
  position: relative;
  flex-direction: column;
  align-items: baseline;
  justify-content: start;
`;

const App = () => {
  const cameras = useCameras();
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [cameraId, setCameraId] = useState<string>();
  const [isLicenseValid, setIsLicenseValid] = useState(false);
  const [userToken, setUserToken] = useState<string>('');
  const [signedToken, setSignedToken] = useState<string>('');
  const [nexterEnv, setNexterEnv] = useState<string>('dev');
  const [policyId, setPolicyId] = useState<string>('');
  const [isMobile] = useState(
    UAParser(navigator.userAgent).device.type === 'mobile',
  );
  const [colorBrand, setColorBrand] = useState<string>('#e5e5e5');
  const [imageBrand, setImageBrand] = useState<string>(null);
  useDisableZoom();

  const updateLicenseStatus = useCallback((valid) => {
    setIsLicenseValid(valid);
  }, []);

  const toggleSettingsClick = useCallback(() => {
    setIsSettingsOpen(!isSettingsOpen);
  }, [isSettingsOpen]);

  const handleCloseSettings = useCallback(({ cameraId }) => {
    setCameraId(cameraId);
    setIsSettingsOpen(false);
  }, []);

  useEffect(() => {
    if (!cameras?.length) return;
    setCameraId(cameras[0].deviceId);
  }, [cameras]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('nexter_user_token');
    const env = queryParams.get('nexter_env');
    const colorBrandParam = queryParams.get('color_brand');
    const imageBrandParam = queryParams.get('image_brand');
    const signedTokenParam = queryParams.get('nexter_signed_token');
    const policyIdParam = queryParams.get('nexter_policy_id');
    if (colorBrandParam) {
      setColorBrand(colorBrandParam);
    }
    if (imageBrandParam) {
      setImageBrand(imageBrandParam);
    }
    setNexterEnv(env || 'dev');
    setUserToken(token || '');
    setSignedToken(signedTokenParam || '');
    setPolicyId(policyIdParam || '');
  }, []);

  return (
    <Container>
      <BinahMonitor
        showMonitor={!(isMobile && isSettingsOpen)}
        cameraId={cameraId}
        onLicenseStatus={updateLicenseStatus}
        onSettingsClick={toggleSettingsClick}
        isSettingsOpen={isSettingsOpen}
        userToken={userToken}
        signedToken={signedToken}
        nexterEnv={nexterEnv}
        colorBrand={colorBrand}
        imageBrand={imageBrand || NexterLogo}
        policyId={policyId}
      />
    </Container>
  );
};

export default App;
