import React from "react";
import styled from "styled-components";

const Button = styled.button<{ variant: string }>`
  background-color: ${({ variant }) => variant};
  border: none;
  color: #fff;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 0.375rem;
`;

export default function NexterButton({ children, variant = "#212529", ...props}) {
  return (
    <Button variant={variant} {...props}>
      {children}
    </Button>
  );
}