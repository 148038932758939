import React, { useEffect, useState } from 'react';
import { Flex } from './shared/Flex';
import styled from 'styled-components';
import { createPortal } from 'react-dom';

const LoadingWrapper = styled(Flex)`
  position: fixed;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: #fff;
`;

export default function Overlay({ children, loading }) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(loading);
  }, [loading]);

  if (!open) return null;

  return createPortal(
    <>
      <LoadingWrapper>{children}</LoadingWrapper>
    </>,
    document.body
  );
};
