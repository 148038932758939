import React, { useState } from 'react';
import styled from 'styled-components';
import StatsBox from './StatsBox';
import { Flex } from './shared/Flex';
import { isMobile } from '@binah/web-sdk';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa6'

const WrapperAlt = styled(Flex)<{ isMobile: boolean }>`
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 3px;
  padding: 10px;
  bottom: ${({ isMobile }) => (isMobile ? '50px' : '80px')};
  box-sizing: border-box;
  z-index: 1010;
`;

const Wrapper = styled(Flex)<{ isMobile: boolean }>`
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 3px;
  padding: 10px;
  bottom: 10px;
  box-sizing: border-box;
  z-index: 1010;
`;

const BoxesWrapper = styled(Flex)`
  gap: 30px;
`;

interface VitalSign<T> {
  value: T;
  isEnabled: boolean;
}

interface IStats {
  /**
   *  Object - contains health stats info
   */
  vitalSigns: {
    heartRate: VitalSign<number>;
    breathingRate: VitalSign<number>;
    stress: VitalSign<number>;
    hrvSdnn: VitalSign<number>;
    spo2: VitalSign<number>;
    bloodPressure: VitalSign<BloodPressureValue>;
  };
}

export type BloodPressureValue = {
  systolic: number;
  diastolic: number;
};

const Stats = ({ vitalSigns }: IStats) => {
  const [openInfo, setOpenInfo] = useState(false)
  const bloodPressureToDisplay =
    vitalSigns.bloodPressure.value?.systolic &&
    vitalSigns.bloodPressure.value?.diastolic
      ? vitalSigns.bloodPressure.value.systolic +
        '/' +
        vitalSigns.bloodPressure.value.diastolic
      : '--';

  return (
    <Wrapper isMobile={isMobile()}>
      {openInfo && (
        <div style={{ width: '100%', marginBottom: '10px' }}>
        <table style={{ width: '100%', borderSpacing: 0  }}>
          <tbody>
            <tr>
              <td>
                <strong>RC:</strong>
              </td>
              <td>Ritmo Cardíaco</td>
            </tr>
            <tr>
              <td>
                <strong>FR:</strong>
              </td>
              <td>Frecuencia Respiratoria</td>
            </tr>
            <tr>
              <td>
                <strong>NE:</strong>
              </td>
              <td>Nivel de Estrés</td>
            </tr>
            {/*<tr>
              <td>
                <strong>SDNN:</strong>
              </td>
              <td>SDNN</td>
            </tr> */}
            <tr>
              <td>
                <strong>PA:</strong>
              </td>
              <td>
                Presión Arterial
              </td>
            </tr>
            <tr>
              <td>
              </td>
              <td>
                {!isMobile() && <small>*Solo funciona en móvil</small>}
              </td>
            </tr>
          </tbody>
        </table>
        <hr />
      </div>
      )}
      <BoxesWrapper>
        <StatsBox
          title={'RC'}
          value={
            vitalSigns.heartRate.isEnabled
              ? vitalSigns.heartRate.value || '--'
              : 'N/A'
          }
        />
        <StatsBox
          title={'FR'}
          value={
            vitalSigns.breathingRate.isEnabled
              ? vitalSigns.breathingRate.value || '--'
              : 'N/A'
          }
        />
        <StatsBox
          title={'NE'}
          value={
            vitalSigns.stress.isEnabled
              ? vitalSigns.stress.value || '--'
              : 'N/A'
          }
        />
       {/*<StatsBox
          title={'SDNN'}
          value={
            vitalSigns.hrvSdnn.isEnabled
              ? vitalSigns.hrvSdnn.value || '--'
              : 'N/A'
          }
        />*/}
        {/*<StatsBox*/}
        {/*  title={'SpO2'}*/}
        {/*  value={*/}
        {/*    vitalSigns.spo2.isEnabled ? vitalSigns.spo2.value || '--' : 'N/A'*/}
        {/*  }*/}
        {/*/>*/}
        {isMobile() && (
          <StatsBox
            title={'PA'}
            value={
              vitalSigns.bloodPressure.isEnabled
                ? bloodPressureToDisplay
                : 'N/A'
            }
          />
        )}
        <div>
          <button
            type="button"
            style={{
              backgroundColor: '#000',
              border: 0,
              color: '#fff',
              borderRadius: '10px',
              display: 'flex',
              alignItems: 'center',
              padding: '5px',
            }}
            onClick={() => setOpenInfo(!openInfo)}
          >
            {openInfo ? <FaAngleDown /> : <FaAngleUp />}
          </button>
        </div>
      </BoxesWrapper>
    </Wrapper>
  );
};

export default Stats;
